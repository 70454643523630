var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subHeader base noFlex qnaReply" }, [
      _c("div", { staticClass: "module-header" }, [
        _c("h2", [_vm._v("문의사항 관리")])
      ]),
      _c("div", { staticClass: "module-config" }, [
        _c("div", { staticClass: "wtBox config-list" }, [
          _c("div", { staticClass: "text" }, [
            _c("p", [_vm._v("답변 내용")]),
            _c("textarea", {
              attrs: { name: "", id: "", cols: "30", rows: "10" }
            })
          ]),
          _c("div", { staticClass: "buttonWrap" }, [
            _c("button", [_vm._v("답변작성")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }